import React from 'react'
import { Container, NavLink, ExternalLink, Mailto } from './styles'

const Navigation = () => {
  return (
    <Container>
      <NavLink to="/terms">Terms</NavLink>
      <ExternalLink href="http://instagram.com/evwhere.nyc" target="_blank">
        Instagram
      </ExternalLink>
      <Mailto email="dispatcher@evwhere.nyc" obfuscate={true}>
        Email
      </Mailto>
    </Container>
  )
}

export default Navigation
