import React, { useContext, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import StoreContext from '~/context/StoreContext'

import SEO from '~/components/seo'

import Shop from '~/components/Shop'
import Navigation from '~/components/Navigation'

// query MyQuery {

const IndexPage = () => {
  const { updateBackground } = useContext(StoreContext)
  const {
    shopifyShopPolicy: { body },
  } = useStaticQuery(
    graphql`
      query {
        shopifyShopPolicy(type: { eq: "privacyPolicy" }) {
          body
        }
      }
    `
  )

  useEffect(() => {
    // find initial mood asset in shop policy body between {{ ... }}
    const url = body
      .split('{{')
      .pop()
      .split('}}')[0]

    const isVideo = url.includes('mp4') ? true : false

    updateBackground({
      video: isVideo,
      asset: url,
    })
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <Shop />
      <Navigation />
    </>
  )
}
export default IndexPage
