import styled from '@emotion/styled'
import { Link } from 'gatsby'
import UnstyledMailto from 'react-mailto'

import { breakpoints } from '~/utils/styles'

export const Container = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2rem;
  justify-content: center;
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`
export const NavLink = styled(Link)`
  padding: 0.3rem 0.3rem;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  margin: 0 1rem 0 0;
  &:hover {
    border-bottom: 1px solid white;
    margin-bottom: -1px;
  }
`
export const ExternalLink = styled.a`
  padding: 0.3rem 0.3rem 0.2rem;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  margin: 0 1rem;
  &:last-of-type {
    margin-right: 0;
  }
  &:hover {
    border-bottom: 1px solid white;
    margin-bottom: -1px;
  }
  &:visted {
    color: white;
  }
`

export const Mailto = styled(UnstyledMailto)`
  padding: 0.3rem 0.3rem 0.2rem;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  margin: 0 1rem;
  &:last-of-type {
    margin-right: 0;
  }
  &:hover {
    border-bottom: 1px solid white;
    margin-bottom: -1px;
  }
  &:visted {
    color: white;
  }
`
